export default {

	UF: { AC: '12', AL: '27', AP: '16', AM: '13', BA: '29', CE: '23', DF: '53', ES: '32', GO: '52', MA: '21', MT: '51', MS: '50', MG: '31', PA: '15', PB: '25', PR: '41', PE: '26', PI: '22', RJ: '33', RN: '24', RS: '43', RO: '11', RR: '14', SC: '42', SP: '35', SE: '28', TO: '17' },

	TIPO_PAGAMENTO: {
		DINHEIRO: 'Dinheiro',
		CARTAO_CREDITO: 'Cartão de Credito',
		CARTAO_DEBITO: 'Cartão de Débito',
		BOLETO: 'Boleto',
		VALE_GAS: 'Vale Gás',
		CHEQUE: 'Cheque',
		FIADO: 'Fiado',
		TRANSFERENCIA: 'Transferência',
		REQUISICAO: 'Requisicão',
		VENDA_ANTECIPADA: 'Venda Antecipada',
		PIX: 'PIX',
		VALE_AGUA: 'Vale Água',
		PICPAY: 'PicPay',
	},

	TIPO_ESTABELECIMENTO: {
		MATRIZ: 'Matriz',
		REVENDA: 'Revenda'
	},

	TIPO_PESSOA: {
		PESSOA_FISICA: 'Pessoa Física',
		PESSOA_JURIDICA: 'Pessoa Jurídica'
	},

	PERFIL_USUARIO: {
		GERENTE: 'Gerente',
		ATENDENTE: 'Atendente',
		PORTARIA: 'Portaria',
		SUPERVISOR: 'Supervisor',
		PROPRIETARIO: 'Proprietário',
		ADMINISTRADOR: 'Administrador'
	},

	PERFIL_CLIENTE: {
		SEM_PERFIL: 'Sem Perfil',
		CONSUMIDOR_FINAL: 'Consumidor Final',
		COMERCIO: 'Comércio',
		PORTARIA: 'Portaria',
		ATACADO: 'Atacado',
		AVIARIO: 'Aviário',
		RESIDENCIAL: 'Residencial',
		SUPERMERCADO: 'Supermercado',
		INDUSTRIAL: 'Industrial',
		ESCOLA: 'Escola',
		PREFEITURA: 'Prefeitura',
		CONDOMINIO: 'Condomínio',
		PADARIA: 'Padaria',
		RESTAURANTE: 'Restaurante',
		BAR: 'Bar',
		HOSPITAL: 'Hospital',
		IGREJA: 'Igreja',
		CONCORRENTE: 'Concorrente',
		REVENDEDOR: 'Revendedor',
		FILIAL: 'Filial',
		FUNCIONARIO: 'Funcionário',
		PONTO_DE_VENDA: 'Ponto de Venda',
		CONVENIO: 'Convênio',
		ROTA: 'Rota'
	},

	TIPO_VEICULO: {
		AUTOMOVEL: 'Automóvel',
		CAMINHAO: 'Caminhão',
		CAMINHONETE: 'Caminhonete',
		CAMIONETA: 'Camioneta',
		CARRETA: 'Carreta',
		CAVALO: 'Cavalo',
		CHASSI_PLATAFORMA: 'Chassi Plataforma',
		MOTOCICLETA: 'Motocicleta',
		MOTOR_CASA: 'Motor Casa',
		QUADRICICLO: 'Quadriciclo',
		REBOQUE: 'Reboque',
		SIDE_CAR: 'Side Car',
		TRICICLO: 'Triciclo',
		UTILITARIO: 'Utilitário'
	},

	SITUACAO_VEICULO: {
		ATIVO: 'Ativo',
		CEDIDO_TERCEIROS: 'Cedido por Terceiros',
		EM_MANUTENCAO: 'Em manutenção',
		FURTADO: 'Furtado',
		INATIVO: 'Inativo',
		SINISTRADO: 'Sinistrado'
	},

	TIPO_ESTOQUE: {
		ESTOQUE_CHEIO: 'Estoque Cheio',
		ESTOQUE_VAZIO: 'Estoque Vazio'
	},

	TIPO_ESTOQUE_NEGOCIADO: {
		ESTOQUE_VAZIO: 'estoqueNegociado:vazio',
		ESTOQUE_CHEIO: 'estoqueNegociado:cheio',
		TODOS: 'estoqueNegociado:todos'
	},

	STATUS_PEDIDO: {
		CANCELADO: 'Cancelado',
		AGENDADO: 'Agendado',
		NOVO: 'Novo',
		REPASSADO: 'Repassado',
		EM_TRANSITO: 'Em Trânsito',
		ENTREGUE: 'Entregue',
		PENDENTE: 'Pendente',
		DEVOLVIDO: 'Devolvido'
	},

	TIPO_CARTAO: {
		CREDITO: 'Crédito',
		DEBITO: 'Débito'
	},

	TIPO_TAXA: {
		CARTAO: 'Cartão',
		BOLETO: 'Boleto'
	},

	CANAL_VENDA: {
		EMAIL: 'Email',
		IMA: 'Ima',
		OUTDOOR: 'Outdoor',
		INTERNET: 'Internet',
		REDE_SOCIAL: 'Rede Social',
		WHATSAPP: 'WhatsApp',
		APLICATIVO: 'Aplicativo',
		PORTARIA: 'Portaria',
		TELEATENDIMENTO: 'Teleatendimento',
		PLANTAO: 'Plantão',
		VENDA_A_PORTA: 'Venda à Porta',
		SINALIZACAO: 'Sinalização',
		IMPRESSO: 'Impresso',
		CLIENTE_FIDELIZADO: 'Cliente Fidelizado',
		AUTOMATICA: 'Automática',
		AGREGADO: 'Agregado',
		COMERCIO_INDUSTRIA: 'Comércio/Indústria',
		CELULAR: 'Celular',
		TELEFONE: 'Telefone',
		CH_APP: 'CH APP',
		PG_APP: 'PG APP',
		UP_APP: 'UP APP',
		QRCODEAPP: 'QRCode APP',
		RADIO: 'Rádio'
	},

	TIPO_CFOP: {
		CONSUMIDOR_FINAL: 'Consumidor Final',
		PONTO_VENDA: 'Ponto de Venda'
	},

	FORMA_PAGAMENTO: {
		A_VISTA: 'A vista',
		A_PRAZO: 'A prazo'
	},

	PERMISSOES_PADROES: [
		'clientes:listar',
		'clientes:buscarPeloTelefone',
		'clientes:cadastrar',
		'clientes:atualizar',
		'clientes:remover',
		'entregadores:listar',
		'entregadores:cadastrar',
		'entregadores:atualizar',
		'entregadores:remover',
		'entregadores:associar_estabelecimento',
		'entregadores:remover_associacao',
		'entregadores:listar_associacoes',
		'estabelecimentos:listar',
		'estabelecimentos:arteQrcode',
		'pedidos:listar',
		'pedidos:cadastrar',
		'pedidos:atualizar',
		'pedidos:remover',
		'pedidos:sincronizar',
		'produtos:listar',
		'produtos:cadastrar',
		'produtos:atualizar',
		'produtos:remover',
		'qrcodesLido:listar',
		'rotas:listar',
		'rotas:cadastrar',
		'rotas:atualizar',
		'rotas:remover',
		'rotas:associacao',
		'taxasTransacao:listar',
		'taxasTransacao:cadastrar',
		'taxasTransacao:atualizar',
		'taxasTransacao:remover',
		'usuarios:listar',
		'usuarios:listar_associacoes',
		'valoresNegociado:listar',
		'valoresNegociado:cadastrar',
		'valoresNegociado:atualizar',
		'valoresNegociado:remover',
		'veiculos:listar',
		'veiculos:cadastrar',
		'veiculos:atualizar',
		'veiculos:remover'
	]

};