<template>
	<v-dialog persistent :width="width" :value="value">
		<v-card :loading="loading">
			<v-alert v-if="type" :icon="type == 'warning' ? 'mdi-alert-circle' : undefined" :type="type" tile class="ma-0" text>
				<slot></slot>
			</v-alert>
			<template v-else>
			<v-container>
				<slot></slot>
			</v-container>
			<v-divider></v-divider>
			</template>
			<v-container>
				<v-btn :disabled="loading" color="primary" @click="$emit('confirm')" class="elevation-0">
					<v-icon left>mdi-check</v-icon> {{confirmButton}}
				</v-btn>
				<v-btn :disabled="loading" color="error" @click="$emit('close')" class="elevation-0">
					<v-icon left>mdi-close</v-icon> {{closeButton}}
				</v-btn>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		confirmButton: {
			type: String,
			default: 'Sim'
		},
		closeButton: {
			type: String,
			default: 'Não'
		},
		width: {
			type: Number,
			default: 300
		},
		value: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: null
		}
	}
};
</script>