<template>
	<v-tabs fixed-tabs v-model="activeTab" hide-slider>
		<v-tab key="calendar">
			<v-icon>mdi-calendar-range</v-icon>
		</v-tab>
		<v-tab key="timer">
			<v-icon>mdi-clock-time-four</v-icon>
		</v-tab>
		<v-tab-item key="calendar">
			<v-date-picker class="elevation-0" @change="change" locale="pt-BR" v-model="date" @input="showTimePicker"></v-date-picker>
		</v-tab-item>
		<v-tab-item key="timer">
			<v-time-picker class="elevation-0" format="24hr" @change="change" locale="pt-BR" ref="timer" v-model="time"></v-time-picker>
		</v-tab-item>
	</v-tabs>
</template>

<script>
import moment from 'moment';
export default {
	props:['value'],
	data() {
		return {
			activeTab: 0,
			date: null,
			time: null,
			datetime: null
		};
	},
	created(){
		this.init();
	},
	methods: {
		showTimePicker() {
			this.activeTab = 1;
		},
		change(){
			if(this.date && this.time) {
				this.$emit('input', moment(this.date + ' ' + this.time, 'YYYY-MM-DD HH:mm').toDate());
			}
		},
		init(){
			this.date = this.value ? moment(this.value).format('YYYY-MM-DD') : null;
			this.time = this.value ? moment(this.value).format('HH:mm') : null;
		}
	},
	watch: {
		value() {
			this.init();
		}
	}
};
</script>