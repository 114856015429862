<template>
	<div>
		<v-menu
			v-model="menu"
			:close-on-content-click="false"
			:nudge-right="40"
			transition="scale-transition"
			offset-y
			min-width="290px"
			v-if="textfield"
		>
			<template v-slot:activator="{ on }">
				<v-text-field
					:outlined="outlined"
					:hide-details="hideDetails"
					:solo="solo"
					:dense="dense"
					:disabled="disabled"
					v-model="dateInputText"
					:label="label"
					readonly
					v-on="on"
					:rules="[...(required ? [v => !!v || 'Campo obrigatório'] : [])]"
				>
					<template v-slot:label v-if="dark">
						<span style="color: #fff">{{label}}</span>
					</template>
				</v-text-field>
			</template>
			<v-datetime-picker dateIcon="mdi-calendar-range" v-if="time" locale="pt-BR" v-model="dateString" @input="changeDate"></v-datetime-picker>
			<v-date-picker class="elevation-0" v-else locale="pt-BR" v-model="dateString" @input="changeDate"></v-date-picker>
		</v-menu>
		<v-datetime-picker dateIcon="mdi-calendar-range" v-else-if="time" locale="pt-BR" v-model="dateString" @input="changeDate"></v-datetime-picker>
		<v-date-picker class="elevation-0" v-else locale="pt-BR" v-model="dateString" @input="changeDate"></v-date-picker>
	</div>
</template>

<script>
import moment from 'moment';
import DateTime from './DateTime.vue';

export default {
	components: {
		'v-datetime-picker': DateTime
	},
	props: {
		value: [Date, String],
		label: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean,
			default: false
		},
		time: {
			type: Boolean,
			default: false
		},
		textfield: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		hideDetails: {
			type: Boolean,
			default: false
		},
		dense: {
			type: Boolean,
			default: false
		},
		solo: {
			type: Boolean,
			default: false
		},
		outlined: {
			type: Boolean,
			default: false
		},
		dark: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			menu: false,
			dateString: null,
			dateInputText: '',
			dateFormat: 'YYYY-MM-DD'
		};
	},
	created(){
		if(this.time) {
			this.dateFormat = 'YYYY-MM-DD HH:mm';
		}
		if(this.value) {
			this.dateString = moment(this.value).format(this.dateFormat);
		}
		this.setDateInputText();
	},
	methods: {
		changeDate(){
			this.$emit('input', this.getDate());
			if(!this.time) {
				this.menu = false;
			}
			this.setDateInputText();
		},
		getDate(){
			return this.dateString ? moment(this.dateString, this.dateFormat).toDate() : null;
		},
		setToday(){
			this.dateString = moment(new Date()).format(this.dateFormat);
			this.$emit('input', this.getDate());
			this.setDateInputText();
		},
		setDateInputText(){
			if(this.value) {
				const d = this.getDate();
				this.dateInputText = d ? moment(d).format(this.time ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY') : null;
			}
		}
	},
	watch: {
		value(v){
			this.dateString = v ? moment(v, this.dateFormat).format(this.dateFormat) : null;
			this.setDateInputText();
		}
	}
};
</script>