<template>
	<v-container fluid fill-height style="background: radial-gradient(at center center, rgb(70, 163, 155) 0px, rgb(74, 96, 155) 100%) !important;">
		<vue-particles class="particles"></vue-particles>
		<v-layout fill-height align-center justify-center>
			<v-card tile width="500" :loading="loading">
				<v-toolbar color="secondary" flat dense dark>
					<v-spacer></v-spacer>
					<v-toolbar-title>VENDERGÁS</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
				<v-container>
					<v-form ref="form" @submit.prevent="login">
						<v-text-field label="Email*" v-model="email" @keydown.enter="login"></v-text-field>
						<v-text-field type="password" label="Senha*" v-model="password" @keydown.enter="login"></v-text-field>
					</v-form>
					<v-btn color="primary" large block @click="login" class="mt-5 elevation-0">
						<v-icon left>mdi-check</v-icon> Entrar
					</v-btn>
				</v-container>
			</v-card>
		</v-layout>
	</v-container>
</template>

<style scoped>
.particles {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
}
</style>

<script>
export default {
	data() {
		return {
			email: null,
			password: null,
			loading: false
		};
	},
	methods: {
		async login() {
			this.loading = true;
			try {
				const { data } = await this.axios.post('/usuarios/auth', {
					email: this.email,
					senha: this.password,
					bina: true
				});
				if(data.token) {
					localStorage.token = data.token;
					localStorage.userId = data.user._id;
				}
				this.$router.push('/teleatendimento');
			}
			catch (err) {}
			this.loading = false;
		}
	}
};
</script>