<template>
	<v-app style="background: #f2f2f2">
		<v-main>
			<router-view></router-view>
		</v-main>

		<v-footer app :color="DEV ? 'red lighten-5' : 'blue lighten-5'"><v-spacer></v-spacer>&copy; Vendergás {{year}} | v{{packageJSON.version}}<v-spacer></v-spacer></v-footer>

		<v-snackbar color="error" v-model="alert">
			<v-btn icon @click="alert = false" dark>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-text v-html="alertMessage"></v-text>
		</v-snackbar>

		<v-dialog v-model="success" width="300" persistent>
			<v-card>
				<v-alert type="success" tile class="ma-0">{{ successMessage }}</v-alert>
				<v-card-actions>
					<v-btn color="secondary" @click="success = false">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-app>
</template>

<script>
import bus from './config/bus';
import packageJSON from '../package.json';

export default {
	data() {
		return {
			alert: false,
			alertMessage: '',
			successMessage: '',
			success: false,
			packageJSON
		};
	},
	created() {
		if (localStorage.token) {
			this.$router.push('/teleatendimento');
		}
	},
	mounted() {
		bus.$on('error', (message) => {
			this.alertMessage = message || 'Erro';
			this.alert = true;
		});
		bus.$on('success', (message) => {
			this.success = true;
			this.successMessage = message || 'Sucesso';
		});
	},
	destroyed() {
		bus.$off('error');
		bus.$off('success');
	},
	computed: {
		year(){
			return new Date().getFullYear();
		}
	}
};
</script>