<template>
	<p>Redirecionando...</p>
</template>

<script>
export default {
	created() {
		this.$router.push('/teleatendimento');
	}	
};
</script>