import Vue from 'vue';
import router from './config/router';
import App from './App.vue';
import vuetify from './config/vuetify';
import VueParticles from 'vue-particles';
import axios from 'axios';
import bus from './config/bus';
import 'share-api-polyfill';

//const production = true;
const production = process.env.NODE_ENV == 'production';

const $axios = axios.create({ baseURL: production ? 'https://api.vendergas.com.br' : 'http://192.168.22.46:3000' });

$axios.interceptors.request.use((config) => {
	if(localStorage.token) {
		config.headers.Authorization = localStorage.token;
	}
	return config;
});

$axios.interceptors.response.use((response) => response, (error) => {
	let message = null;
	if(error.response) {
		message = error.response.data.message || error.message;
	}
	else {
		message = error.message;
	}
	bus.$emit('error', message);
	return Promise.reject(error);
});

Vue.use(VueParticles);

Vue.mixin({
	data() {
		return {
			axios: $axios,
			DEV: process.env.NODE_ENV == 'development'
		};
	}
});

new Vue({
	el: '#app',
	render: h => h(App),
	vuetify,
	router
});

//Configurando service-worker
if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker.register('/sw.js').then(
			() => {
				console.log('SERVICE WORKER REGISTRADO');
			},
			(err) => {
				console.log('FALHA AO REGISTRAR SERVICE WORKER', err);
			}
		);
	});
}