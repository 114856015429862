import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../pages/login.vue';
import Teleatendimento from '../pages/teleatendimento.vue';
import Refresh from '../pages/refresh.vue';

Vue.use(VueRouter);

export default new VueRouter({
	routes: [
		{ path: '/', redirect: '/login' },
		{ path: '/login', component: Login },
		{ path: '/teleatendimento', component: Teleatendimento },
		{ path: '/refresh', component: Refresh }
	]
});