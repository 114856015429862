<template>
	<div>
		<v-app-bar color="primary" dark app>
			<v-toolbar-title>TELEGÁS</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-tooltip bottom>
				<template v-slot:activator="{on, attrs}">
					<v-btn icon v-on="on" v-bind="attrs" @click="logoutDialog = true">
						<v-icon>mdi-power</v-icon>
					</v-btn>
				</template>
				<span>Sair</span>
			</v-tooltip>
			<v-divider vertical class="mx-3"></v-divider>
			<v-switch label="NFCE" style="transform: translateY(10px)" v-model="podeEmitirNFCE"></v-switch>
		</v-app-bar>
		<v-container fluid class="mt-3">
			<v-form ref="form" autocomplete="off">
				<v-card dark color="secondary" class="px-5 mb-5 white--text">
					<v-row>
						<v-col cols="12" sm="6" md="4" lg="2">
							<v-btn block height="56" color="primary" @click="openBuscarCliente" :disabled="!estabelecimento">
								<v-icon left>mdi-magnify</v-icon>
								Buscar cliente
							</v-btn>
						</v-col>
						<v-col cols="12" sm="6" md="4" lg="2" class="text-center">
							<v-text-field :disabled="!estabelecimento" type="number" hide-details outlined v-model="cliente.telefonePrincipal" :rules="[rules.required]">
								<template v-slot:label>
									<span style="color: #fff">Telefone</span>
								</template>
							</v-text-field>
							<a href="#" style="color: #fff;" @click="telefonesDialog = true">ADICIONAR TELEFONE</a>
						</v-col>
						<v-col cols="12" sm="5" md="4" lg="3" class="destacado">
							<v-select :loading="loadingEstabelecimentos" outlined hide-details v-model="estabelecimento" :rules="[rules.required]" :items="estabelecimentos" item-text="nomeFantasia" return-object placeholder="Selecionar Estabelecimento"></v-select>
						</v-col>
						<v-col cols="12" sm="5" md="4" lg="2">
							<Datepicker ref="dataEntrega" dark outlined hide-details label="Data de Entrega" v-model="dataEntrega" required time></Datepicker>
						</v-col>
						<v-col cols="12" sm="2" lg="3">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" light :height="$vuetify.breakpoint.mdAndUp ? 56 : undefined" small @click="limparCliente">
										<v-icon v-if="$vuetify.breakpoint.mdAndUp">mdi-eraser</v-icon>
										<span v-else>Limpar</span>
									</v-btn>
								</template>
								<span>Limpar</span>
							</v-tooltip>
							<v-btn @click="salvarCliente" :disabled="!cliente.nome || !cliente.telefonePrincipal" :height="$vuetify.breakpoint.mdAndUp ? 56 : undefined" small color="primary">Salvar cliente</v-btn>
						</v-col>
					</v-row>
				</v-card>
				<v-row>
					<v-col cols="12" sm="6" md="4" lg="2">
						<v-select clearable outlined hide-details dense label="Tipo de Cliente" :items="tiposPessoa" v-model="cliente.tipoPessoa"></v-select>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3">
						<v-text-field @input="cliente.nome = (($event || '').toUpperCase())" ref="campoNome" outlined hide-details dense :label="cliente.tipoPessoa == enume.TIPO_PESSOA.PESSOA_JURIDICA ? 'Nome Fantasia' : 'Nome do Cliente'" v-model="cliente.nome" :rules="[rules.required]"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="2">
						<busca-endereco v-model="cliente.endereco.logradouro"></busca-endereco>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="1">
						<v-text-field @input="cliente.endereco.numero = (($event || '').toUpperCase())" outlined hide-details dense label="Nº" v-model="cliente.endereco.numero"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="2">
						<v-text-field @input="cliente.endereco.complemento = (($event || '').toUpperCase())" outlined hide-details dense label="Complemento" v-model="cliente.endereco.complemento"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="2" v-if="$vuetify.breakpoint.mdAndUp">
						<v-text-field @input="cliente.endereco.bairro = (($event || '').toUpperCase())" outlined hide-details dense label="Bairro" v-model="cliente.endereco.bairro"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="2" v-if="$vuetify.breakpoint.mdAndUp">
						<v-text-field @input="cliente.endereco.ibge = (($event || '').toUpperCase())" type="number" outlined hide-details dense label="Cod. Município" v-model="cliente.endereco.ibge"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="1" v-if="$vuetify.breakpoint.mdAndUp">
						<v-select outlined hide-details dense label="UF" :items="UFs" v-model="cliente.endereco.uf"></v-select>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="2" v-if="$vuetify.breakpoint.mdAndUp">
						<v-text-field @input="cliente.endereco.localidade = (($event || '').toUpperCase())" outlined hide-details dense label="Cidade" v-model="cliente.endereco.localidade"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="2">
						<v-select clearable outlined hide-details dense label="Canal de Venda" :items="canaisVenda" v-model="indicacaoCompra"></v-select>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="1" v-if="$vuetify.breakpoint.mdAndUp">
						<v-text-field @input="cliente.endereco.cep = (($event || '').toUpperCase())" outlined hide-details dense label="CEP" v-model="cliente.endereco.cep"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="2" v-if="$vuetify.breakpoint.mdAndUp">
						<v-text-field @input="cliente.email = (($event || '').toUpperCase())" outlined hide-details dense label="Email do Cliente" v-model="cliente.email"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="2">
						<v-select :loading="loadingProdutos" clearable outlined hide-details dense label="Produto" :items="produtos" item-text="nome" item-value="_id" v-model="cliente.produtoId"></v-select>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="2" v-if="$vuetify.breakpoint.mdAndUp">
						<Datepicker outlined hide-details dense label="Data de Aniversário" v-model="cliente.aniversario"></Datepicker>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="1" v-if="$vuetify.breakpoint.mdAndUp">
						<v-text-field @input="cliente.prazoVencimento = (($event || '').toUpperCase())" outlined hide-details dense label="Prazo de Vencimento" type="number" min="0" v-model="cliente.prazoVencimento"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="2">
						<v-select clearable outlined hide-details dense label="Perfil do Cliente" :items="perfisCliente" v-model="cliente.perfil"></v-select>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="2" v-if="$vuetify.breakpoint.mdAndUp">
						<v-select clearable outlined hide-details dense label="Tipo CFOP" :items="tiposCFOP" v-model="cliente.tipoCFOP"></v-select>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="1">
						<v-text-field @input="cliente.cpf = (($event || '').toUpperCase())" type="number" outlined hide-details dense label="CPF" v-model="cliente.cpf"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="4">
						<v-textarea outlined hide-details dense rows="2" label="Observação do cliente" v-model="cliente.endereco.observacao"></v-textarea>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="4">
						<v-textarea outlined hide-details dense rows="2" label="Observação do pedido" v-model="observacao"></v-textarea>
					</v-col>
					<v-col cols="12" sm="6" md="4">
						<v-select :disabled="!estabelecimento" clearable outlined hide-details dense :loading="loadingEntregadores" v-model="entregadorId" label="Selecionar Entregador" :items="entregadores" item-text="nome" item-value="_id"></v-select>
					</v-col>
				</v-row>
				<template v-if="cliente.tipoPessoa == enume.TIPO_PESSOA.PESSOA_JURIDICA">
					<v-sheet color="secondary" dark class="py-1 px-2 mb-2 mt-3 elevation-1">
						<i class="mdi mdi-domain"></i> Informações de cliente pessoa jurídica
					</v-sheet>
					<v-row>
						<v-col cols="12" sm="6" md="4" lg="3">
							<v-text-field @input="cliente.cnpj = (($event || '').toUpperCase())" type="number" hide-details dense outlined label="CNPJ" v-model="cliente.cnpj"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="4" lg="3">
							<v-text-field @input="cliente.razaoSocial = (($event || '').toUpperCase())" hide-details dense outlined label="Razão Social" v-model="cliente.razaoSocial"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="4" lg="3">
							<v-text-field @input="cliente.inscricaoEstadual = (($event || '').toUpperCase())" type="number" hide-details dense outlined label="Inscrição Estadual" v-model="cliente.inscricaoEstadual"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="4" lg="3">
							<v-text-field @input="cliente.inscricaoMunicipal = (($event || '').toUpperCase())" type="number" hide-details dense outlined label="Inscrição Municipal" v-model="cliente.inscricaoMunicipal"></v-text-field>
						</v-col>
					</v-row>
				</template>
			</v-form>
			<v-sheet color="secondary" dark class="py-1 px-2 mb-2 mt-3 elevation-1">
				<i class="mdi mdi-cart"></i> Produtos
			</v-sheet>
			<v-card outlined class="mb-8" :disabled="!!pagamentosSelect.length">
				<v-data-table hide-default-footer :headers="headerProdutos" :items="pedidoItems">
					<template v-slot:[`item.tipoEstoque`]="{ item }">
						<v-select disabled outlined dense hide-details style="width: 170px" value="Estoque único" :items="['Estoque único']" v-if="item.produto.isUnico"></v-select>
						<v-select outlined dense hide-details style="width: 170px" v-model="item.tipoEstoque" :items="tiposEstoque" @change="alterarTipoEstoque(item)" v-else></v-select>
					</template>
					<template v-slot:[`item.utilizarValorNegociado`]="{ item }">
						<v-switch style="float:left" v-model="item.utilizarValorNegociado" :disabled="!cliente || !item.valorNegociadoId"></v-switch>
						<v-btn style="float:left; margin-top: 16px;" icon :disabled="!cliente.nome || !cliente.telefonePrincipal" @click="editarVn(item)">
							<v-icon color="indigo">mdi-handshake</v-icon>
						</v-btn>
					</template>
					<template v-slot:[`item.quantidade`]="{ item }">
						<v-text-field type="number" hide-details outlined dense v-model="item.quantidade" style="width:150px" min="1">
							<template v-slot:append-outer>
								<v-icon size="30" color="green" @click="item.quantidade++">mdi-plus-circle</v-icon>
							</template>
							<template v-slot:prepend>
								<v-icon size="30" color="red" @click="item.quantidade > 1 ? item.quantidade-- : null">mdi-minus-circle</v-icon>
							</template>
						</v-text-field>
					</template>
					<template v-slot:[`item.valorUnitario`]="{ item }">
						{{ (obterValorUnitario(item) || '---') | reais }}
					</template>
					<template v-slot:[`item.valorTotal`]="{ item }">
						{{ (obterValorTotal(item) || '---') | reais }}
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-btn icon @click="removeItem(item)">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</template>
				</v-data-table>
				<v-card-actions>
					<v-row>
						<v-col cols="12" xs="12" sm="6">
							<v-select @change="addProduto" :loading="loadingProdutos" clearable outlined hide-details dense label="Selecionar produto" v-model="produto" :items="produtos" item-text="nome" return-object></v-select>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
            <span v-if="showPagamentoBloqueadoText"
                style="margin-left: 60px; margin-top: 27px; color: red; font-weight: 600;">
                Forma de pagamento bloqueada para este cliente!
                <br>
                <br>
            </span>

			<v-form>
				<v-row>
					<v-col cols="12" xs="12" md="6" v-if="$vuetify.breakpoint.mdAndUp" :style="$vuetify.breakpoint.mdAndUp ? 'border: 1px solid #CCC; border-left: none; border-right: none;' : null">
						<fieldset class="vg-fieldset pa-0 ma-0">
							<legend class="ma-0 pa-0 mb-3">Forma de pagamento</legend>
							<v-row class="px-3">
								<v-checkbox @change="togglePagamento(formaPagamento, $event)" 
                                class="mr-5" :disabled="!pedidoItems.length" hide-details  
                                v-model="pagamentosSelecionados[formaPagamento].selecionado" 
                                v-for="formaPagamento in tiposPagamento" :key="formaPagamento" 
                                :label="formaPagamento"></v-checkbox>
							</v-row>
						</fieldset>
					</v-col>
					<v-col cols="12" xs="12" sm="6" v-else>
						<v-select :disabled="!pedidoItems.length" clearable ref="selectPagamento" v-model="pagamentosSelect" multiple label="Forma de pagamento" hide-details dense outlined :items="tiposPagamento">
							<template v-slot:prepend-item>
								<v-btn tile text large class="pa-0" color="primary" block @click="$refs.selectPagamento.blur()">Fechar</v-btn>
								<v-divider></v-divider>
							</template>
							<template v-slot:item="{ item }">
								<v-list-item>
									<v-list-item-content> 
										<v-checkbox @change="togglePagamento(item, $event)" class="ml-2" hide-details v-model="pagamentosSelecionados[item].selecionado" :label="item"></v-checkbox>
									</v-list-item-content>
								</v-list-item>
							</template>
						</v-select>
					</v-col>
					<v-col cols="12" xs="12" md="3" :style="$vuetify.breakpoint.mdAndUp ? 'border: 1px solid #CCC;' : null">
						<span class="headline font-weight-thin">Total</span>
						<br>
						<span class="display-1 font-weight-thin">R$ {{obterTotalDaCompra().toFixed(2)}}</span>
						<br>
						<div v-if="cliente._credito > 0" >

							<span class="caption">Crédito Disponivel</span>
							<br>
							<span style="font-size: 8pt; color: #008000;" class="caption">R$ {{cliente._credito.toFixed(2)}} </span>
							<span style="font-size: 8pt; color: #FF0B58;" class="caption">( - {{pagamentosSelecionados ? `R$ ${(Number(pagamentosSelecionados[enume.TIPO_PAGAMENTO.VENDA_ANTECIPADA].valor) || 0).toFixed(2)}` : 'R$ 0.00' }})</span>
							<br>
					</div>
						<span class="caption">Total pago</span>
						<br>
						<span class="caption">R$ {{(obterTotalDaCompra() - obterValorRestante()).toFixed(2)}}</span>
					</v-col>
					<v-col cols="12" xs="12" md="3" :style="$vuetify.breakpoint.mdAndUp ? 'border: 1px solid #CCC; border-left: none; border-right: none;' : null">
						<v-btn large block class="ma-2 ml-0" color="secondary" @click="enviarPedido(false)">
							<v-icon left>mdi-chevron-right</v-icon>Finalizar Pedido
						</v-btn>
						<v-btn large block class="ma-2 ml-0" color="primary" @click="openAgendamento">
							<v-icon left>mdi-calendar</v-icon> Agendar Pedido
						</v-btn>
						<v-btn large block class="ma-2 ml-0" color="purple" dark @click="cupomDialog">
							<v-icon left>mdi-file-document</v-icon> Emitir Cupom Fiscal
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-container>

		<v-dialog persistent width="1200" v-model="buscarCliente" scrollable>
			<v-card :loading="loadingClientes">
				<template slot="progress">
					<v-progress-linear color="secondary" indeterminate></v-progress-linear>
				</template>
				<v-toolbar flat dense color="indigo" dark>
					<b>Buscar cliente</b>
					<v-spacer></v-spacer>
					<v-btn icon @click="clientes = []" v-if="clientes.length > 0">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
					<v-btn icon @click="buscarCliente = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-sheet color="secondary" class="pa-3" tile v-if="clientes.length == 0">
					<v-row>
						<v-col cols="12" md="6" lg="3">
							<v-text-field flat :disabled="loadingClientes" @focus="resetBuscaCliente('documento')" v-model="clienteDocumento" label="CPF/CNPJ" solo dense hide-details class="ma-0" @keydown.enter="carregarClientes"></v-text-field>
						</v-col>
						<v-col cols="12" md="6" lg="3">
							<v-text-field flat :disabled="loadingClientes" @focus="resetBuscaCliente('telefone')" v-model="clienteTelefone" label="Telefone" solo dense hide-details class="ma-0" @keydown.enter="carregarClientes"></v-text-field>
						</v-col>
						<v-col cols="12" md="6" lg="3">
							<v-text-field flat :disabled="loadingClientes" @focus="resetBuscaCliente('nome')" v-model="clienteNome" label="Nome" solo dense hide-details class="ma-0" @keydown.enter="carregarClientes"></v-text-field>
						</v-col>
						<v-col cols="12" md="6" lg="3">
							<v-text-field flat :disabled="loadingClientes" @focus="resetBuscaCliente('endereco')" v-model="clienteEndereco" label="Endereço" solo dense hide-details class="ma-0" @keydown.enter="carregarClientes"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-btn color="primary" dark @click="carregarClientes">Buscar</v-btn>
						</v-col>
					</v-row>
				</v-sheet>
				<v-card-text v-if="clientes.length > 0" class="pa-0">
					<v-data-table :headers="headerClientes" :items="clientes" @click:row="selecionarCliente">
						<template v-slot:[`item.documento`]="{ item }">{{item.cpf || item.cnpj}}</template>
						<template v-slot:[`item.telefone`]="{ item }">{{item | telefones}}</template>
						<template v-slot:[`item.nome`]="{ item }">
							{{item.nome}}
							<p style="font-size: 8pt; color: #777;" class="mb-0" v-if="item.razaoSocial && item.nome != item.razaoSocial">{{item.razaoSocial}}</p>
						</template>
						<template v-slot:[`item.endereco`]="{ item }">{{item.enderecos | endereco | small(300)}}</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog width="300" v-model="vndialog">
			<v-card v-if="vndialog" :loading="vnLoading">
				<v-toolbar flat>
					<span>Definir Valor Negociado</span>
					<v-spacer></v-spacer>
					<v-icon @click="vndialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<v-form ref="vnform" autocomplete="off">
						<v-text-field :rules="[rules.required]" v-model="itemCopy.vn" label="Valor Negociado" type="number" min="0"></v-text-field>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" :disabled="vnLoading" @click="salvarVn">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-menu :position-x="x" :position-y="y" :value="!!pagamentoPopup" :close-on-content-click="false" :close-on-click="false" top nudge-top="25" offset-x offset-y>
			<v-card dark color="purple" class="white--text">
				<v-system-bar window color="purple" dark v-if="pagamentoPopup">
					<v-icon>mdi-currency-usd</v-icon>
					<span class="caption font-weight-bold white--text">OPÇÕES DE {{pagamentoPopup.toUpperCase()}}</span>
					<v-spacer></v-spacer>
					<v-icon @click="pagamentoPopup = false">mdi-close</v-icon>
				</v-system-bar>
				<v-divider color="white"></v-divider>
				<v-card-text>
					<v-form v-if="pagamentoPopup" autocomplete="off" ref="pagform">
						<v-select clearable v-model="taxaTransacaoId" 
                            v-if="pagamentoPopup == enume.TIPO_PAGAMENTO.BOLETO 
                            || pagamentoPopup == enume.TIPO_PAGAMENTO.CARTAO_CREDITO 
                            || pagamentoPopup == enume.TIPO_PAGAMENTO.CARTAO_DEBITO" 
                            dense outlined :loading="loadingTaxas" label="Taxa de Transação" 
                            :items="taxas" item-value="_id">
                            <template v-slot:selection="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title>{{item.tipoCartao == "boleto" ? "Boleto" : item.tipoCartao == "cartao" ? "Cartão" : "" }} {{item.bandeira}}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <template v-slot:item="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title><b>{{item.tipoCartao == "boleto" ? "Boleto" : item.tipoCartao == "cartao" ? "Cartão" : "" }}</b> {{item.bandeira}}</v-list-item-title>
                                </v-list-item-content>
							</template>
                        </v-select>
						<v-text-field v-model="troco" v-if="pagamentoPopup == enume.TIPO_PAGAMENTO.DINHEIRO" dense outlined type="number" min="0" step="0.01" label="Troco"></v-text-field>
						<v-text-field v-if="mostrarCampoValor" v-model="popupValues.valor" dense type="number" min="0" step="0.01" outlined label="Valor"></v-text-field>
						<Datepicker label="Vencimento" outlined dense v-model="popupValues.dataVencimento" v-if="pagamentoPopup == enume.TIPO_PAGAMENTO.FIADO"></Datepicker>
						<v-text-field v-if="pagamentoPopup == enume.TIPO_PAGAMENTO.CHEQUE" v-model="popupValues.numeroCheque" dense type="number" outlined label="Número do Cheque"></v-text-field>
						<v-text-field @input="popupValues.nomeBanco = (($event || '').toUpperCase())" v-if="pagamentoPopup == enume.TIPO_PAGAMENTO.CHEQUE" v-model="popupValues.nomeBanco" dense outlined label="Nome do Banco"></v-text-field>
						<v-select clearable dense outlined label="Forma de Pagamento" v-if="pagamentoPopup == enume.TIPO_PAGAMENTO.CHEQUE" :items="modosPagamento" v-model="popupValues.formaPagamento"></v-select>
						<v-select clearable dense outlined label="Parcelamento" v-if="pagamentoPopup == enume.TIPO_PAGAMENTO.CARTAO_CREDITO" :items="parcelamentos" v-model="parcelamento"></v-select>
					</v-form>
				</v-card-text>
			</v-card>
		</v-menu>

		<v-dialog width="290" v-model="agendamentoDialog" persistent scrollable>
			<v-card>
				<v-toolbar flat>
					<span>Data do Agendamento</span>
					<v-spacer></v-spacer>
					<v-icon @click="fecharAgendamento">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<Datepicker time ref="dataAgendamento" :textfield="false" v-model="dataEntrega"></Datepicker>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn @click="enviarPedido(true)" color="secondary">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog width="300" v-model="telefonesDialog" persistent scrollable>
			<v-card>
				<v-toolbar flat dense class="caption">
					<v-spacer></v-spacer>
					<span>INFORME OS TELEFONES ADICIONAIS</span>
					<v-spacer></v-spacer>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text class="pt-5">
					<v-form>
						<v-text-field label="Digite o telefone" v-for="(tel, i) in cliente.telefones" :key="i" v-model="cliente.telefones[i]">
							<template v-slot:append-outer>
								<v-icon size="30" @click="cliente.telefones.splice(i, 1)" color="red">mdi-minus-circle</v-icon>
							</template>
						</v-text-field>
					</v-form>
					<v-btn block text @click="cliente.telefones.push('')">
						<v-icon>mdi-plus</v-icon>
						<span>Adicionar</span>
					</v-btn>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn @click="telefonesDialog = false" color="secondary">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog width="400" persistent v-model="nfcePopup">
			<v-card :loading="nfceLoading">
				<v-toolbar flat dense class="caption">
					<v-spacer></v-spacer>
					<b>EMITIR NOTA FISCAL (NFC-E)</b>
					<v-spacer></v-spacer>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text class="pt-5">
					<p class="caption">Clique em "Não emitir" caso não queira emitir</p>
					<p class="caption" v-if="emitNfceOnly">Você está apenas emitindo um cupom fiscal sem lançamento de pedido.</p>
					<v-form>
						<v-text-field v-model="nfceValues.docCliente" label="CPF/CNPJ do cliente"></v-text-field>
						<v-text-field v-if="pagamentosSelecionados[enume.TIPO_PAGAMENTO.CARTAO_CREDITO].selecionado || pagamentosSelecionados[enume.TIPO_PAGAMENTO.CARTAO_DEBITO].selecionado" v-model="nfceValues.autorizacaoCartao" label="Código de autorização do cartão"></v-text-field>
						<v-text-field v-model="nfceValues.infAdicionais" label="Informações Adicionais"></v-text-field>
						<v-checkbox v-model="nfceValues.sendToEmail" label="Enviar por email"></v-checkbox>
						<v-select :items="smtpConfigs" item-text="user" item-value="_id" v-if="nfceValues.sendToEmail" v-model="nfceValues.idSmtpConfig" label="De" :loading="smtpConfigLoading"></v-select>
						<v-text-field v-if="nfceValues.sendToEmail" v-model="nfceValues.to" label="Para"></v-text-field>
						<v-checkbox v-model="nfceValues.baixarPdf" label="Baixar PDF"></v-checkbox>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn :disabled="nfceLoading" color="primary" @click="emitirCupom" v-if="emitNfceOnly">Emitir nota</v-btn>
					<v-btn :disabled="nfceLoading" color="primary" @click="emitirNota" v-else>Emitir nota</v-btn>
					<v-btn :disabled="nfceLoading" color="error" @click="nfcePopup = false" v-if="emitNfceOnly">Não emitir</v-btn>
					<v-btn :disabled="nfceLoading" color="error" @click="fecharNFCePopup" v-else>Não emitir</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="loading" width="300" persistent>
			<v-card>
				<v-container fluid>
					<v-progress-linear indeterminate></v-progress-linear>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog v-model="opcaoBoletoDialog" persistent width="600">
			<v-card color="#8145e9" dark :loading="loadingBoleto">
				<v-system-bar color="#8145e9">
					<v-icon>mdi-view-list</v-icon>
					Selecione uma opção abaixo
					<v-spacer></v-spacer>
					<v-icon @click="opcaoBoletoDialog = false">mdi-close</v-icon>
				</v-system-bar>
				<v-divider></v-divider>
				<v-card-text>
					<v-form ref="formBoleto">
						<Datepicker v-model="boletoVencimento" label="Vencimento do boleto" required></Datepicker>
						<v-text-field label="CPF/CNPJ do cliente" :rules="[rules.required]" v-if="!cliente.cpf && !cliente.cnpj" v-model="clienteCpfCnpj"></v-text-field>
						<v-checkbox v-model="baixarPdfBoleto" label="Baixar PDF"></v-checkbox>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-row>

						<v-col cols="12" md="6" lg="6">

							<v-btn color="secondary" @click="emitirBoleto">
								<v-icon>mdi-arrow-collapse-down</v-icon> Emitir boleto
							</v-btn>
						</v-col>
						<v-col cols="12" md="6" lg="6">

							<v-btn color="secondary" @click="opcaoRegFinancDialog = true; opcaoBoletoDialog = false; valorBoleto = obterTotalDaCompra()">
								<v-icon>mdi-arrow-collapse-down</v-icon> Registrar financeiro
							</v-btn>
						</v-col>
						<v-col cols="12" md="6" lg="6">
							<v-btn color="red" @click="naoEmitirBoleto">Não emitir</v-btn>
						</v-col>
					</v-row> 
				</v-card-actions>
			</v-card>
		</v-dialog>

        <v-dialog v-model="opcaoRegFinancDialog" persistent width="600">
			<v-card color="#8145e9" dark :loading="loadingBoleto">
				<v-system-bar color="#8145e9">
					<v-icon>mdi-view-list</v-icon>
					Selecione uma opção abaixo
					<v-spacer></v-spacer>
					<v-icon @click="opcaoRegFinancDialog = false">mdi-close</v-icon>
				</v-system-bar>
				<v-divider></v-divider>
				<v-card-text>
					<v-form ref="formBoleto">
						<v-text-field label="Valor do Boleto" v-model="valorBoleto" type="number"></v-text-field>
                        <v-text-field label="Número do boleto" v-model="numeroBoleto"></v-text-field>
						<Datepicker v-model="boletoVencimento" label="Vencimento do boleto" required></Datepicker>

                    </v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
                    <v-btn color="secondary" @click="registrarFinanceiro">
						<v-icon>mdi-arrow-collapse-down</v-icon> Registrar financeiro
					</v-btn>
					<v-btn color="red" @click="naoRegistrarFinanceiro">Cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<confirm v-model="logoutDialog" @confirm="logout" @close="logoutDialog = false">Deseja sair?</confirm>
		<confirm v-model="clienteDialog" @confirm="cadastrarCliente(true)" @close="clienteDialog = false"> O telefone {{infoCliente.telefone}} já foi cadastrado, com os seguintes dados:</confirm>

	</div>
</template>

<script>
import rules from '../config/rules';
import _enum from '../config/enum';
import Datepicker from '../components/Datepicker.vue';
import BuscaEndereco from '../components/BuscaEndereco.vue';
import Confirm from '../components/Confirm.vue';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import bus from '../config/bus';

const SHARE_OPTIONS = {
	copy: true,
	email: true,
	print: true,
	sms: true,
	messenger: true,
	facebook: false,
	whatsapp: true,
	twitter: false,
	linkedin: false,
	telegram: true,
	skype: false,
	pinterest: false,
	language: 'pt'
};

const formatPhone = (phone, autoDDD = '61') => {
	if (!phone) return null;
	const onlyNumber = phone.trim().replace(/[^\d]/g, '');
	const clearZeros = onlyNumber.replace(/^0+/, '');
	if (onlyNumber.length < 8) return null;
	const ddd = clearZeros.length > 9 ? clearZeros.substr(0, 2) : (autoDDD != null ? autoDDD : '');
	const eightDigits = clearZeros.replace(/^\d+(\d{8})$/, '$1');
	const withNine = parseInt(eightDigits[0]) >= 6 ? '9' + eightDigits : eightDigits;
	return ddd + withNine;
};

const clear = str => (str || '').replace(/[^\d]/g, '');

const getEnderecoDefault = (enderecos) => {
	let enderecoDefault = null;
	if (enderecos) {
		if (enderecos.length > 0) { enderecoDefault = enderecos[0]; }
		for (const end of enderecos) {
			delete end.historicoAcaos;
			if (end.isDefault) {
				enderecoDefault = end;
				break;
			}
		}
	}
	if (enderecoDefault) {
		if (enderecoDefault.uf == null && enderecoDefault.estadoAcronimo != null) enderecoDefault.uf = enderecoDefault.estadoAcronimo;
		else if (enderecoDefault.estadoAcronimo == null && enderecoDefault.uf != null) enderecoDefault.estadoAcronimo = enderecoDefault.uf;
	}
	return enderecoDefault || {};
};

export default {
	components: {
		Datepicker,
		BuscaEndereco,
		Confirm
	},
	filters: {
		telefones(cliente) {
			return [cliente.telefone, ...(cliente.outrosTelefones || [])].filter(x => !!x && !!x.trim()).join(', ');
		},
		endereco(enderecos) {
			const e = getEnderecoDefault(enderecos);
			if (e) {
				return [e.endereco, e.complemento, e.bairro, e.numero, e.cidade].filter(i => !!i).join(',');
			}
			return '---';
		},
		reais(valor) {
			return isNaN(valor) ? valor : `R$ ${Number(valor).toFixed(2)}`;
		},
		small(valor, size) {
			return valor.length > size ? valor.substr(0, size - 3) + '...' : valor;
		}
	},
	data() {
		return {
			$x: 0,
			$y: 0,
			x: 0,
			y: 0,
			vnLoading: false,
			valorTaxa: 0,
			pagamentoPopup: false,
			mostrarCampoValor: false,
			taxaTransacaoId: null,
			logoutDialog: false,
			clienteDialog: false,
			infoCliente: {},
			troco: null,
			popupValues: {},
			podeEmitirNFCE: localStorage.podeEmitirNFCE != 'false' ? true : false,
			item: null,
			itemCopy: null,
			vndialog: false,
			pedidoEmitido: null,
			userInfo: null,
			percentualValue: null,
			valoresNegociados: [],
			observacao: null,
			buscarCliente: false,
			nfceLoading: false,
			enume: _enum,
			pedidoItems: [],
			estabelecimento: { _id: null },
			dataEntrega: new Date(),
			parcelamento: 1,
			loadingClientes: false,
			loadingProdutos: false,
			indicacaoCompra: _enum.CANAL_VENDA.TELEATENDIMENTO,
			entregadorId: null,
			agendamentoDialog: false,
			telefonesDialog: false,
			loading: false,
			clientes: [],
			smtpConfigs: [],
			emitNfceOnly: false,
			loadingTaxas: false,
			showPagamentoBloqueadoText: false,
			taxas: [],
			msgPagBloqueadoTimeout: undefined,
			smtpConfigLoading: false,
			rules,
			nfcePopup: false,
			tiposPessoa: Object.values(_enum.TIPO_PESSOA),
			cliente: {
				_id: null,
				endereco: {},
				telefones: [],
				tipoPessoa: _enum.TIPO_PESSOA.PESSOA_FISICA,
				_credito: 0
			},
			UFs: Object.keys(_enum.UF),
			canaisVenda: Object.values(_enum.CANAL_VENDA),
			produtos: [],
			perfisCliente: Object.values(_enum.PERFIL_CLIENTE),
			tiposCFOP: Object.values(_enum.TIPO_CFOP),
			loadingEntregadores: false,
			loadingEstabelecimentos: false,
			entregadores: [],
			pagamentosSelect: [],
			produto: null,
			tiposPagamento: Object.values(_enum.TIPO_PAGAMENTO),
			pagamentosSelecionados: {},
			estabelecimentos: [],
			headerProdutos: [
				{ text: 'Produto', value: 'produto.nome', sortable: false },
				{ text: 'Tipo do Estoque', value: 'tipoEstoque', sortable: false },
				{ text: 'Valor Negociado', value: 'utilizarValorNegociado', sortable: false },
				{ text: 'Quantidade', value: 'quantidade', sortable: false },
				{ text: 'Valor Unitário', value: 'valorUnitario', sortable: false },
				{ text: 'Valor Total', value: 'valorTotal', sortable: false },
				{ text: '', value: 'actions', sortable: false, align: 'right', width: 148 }
			],
			headerClientes: [
				{ text: 'CPF/CNPJ', value: 'documento', sortable: false },
				{ text: 'Telefone', value: 'telefone', sortable: false },
				{ text: 'Nome', value: 'nome', sortable: false },
				{ text: 'Endereço', value: 'endereco', sortable: false },
			],
			clienteDocumento: null,
			clienteTelefone: null,
			clienteNome: null,
			clienteEndereco: null,
			tiposEstoque: Object.values(_enum.TIPO_ESTOQUE),
			nfceValues: {
				docCliente: null,
				autorizacaoCartao: null,
				sendToEmail: false,
				idSmtpConfig: null,
				to: null,
				baixarPdf: true,
				compartilhar: false,
				infAdicionais: null
			},
			parcelamentos: [
				{ value: 1, text: 'à vista' },
				{ value: 2, text: '2x' },
				{ value: 3, text: '3x' },
				{ value: 4, text: '4x' },
				{ value: 5, text: '5x' },
				{ value: 6, text: '6x' },
				{ value: 7, text: '7x' },
				{ value: 8, text: '8x' },
				{ value: 9, text: '9x' },
				{ value: 10, text: '10x' },
				{ value: 11, text: '11x' },
				{ value: 12, text: '12x' }
			],
			opcaoBoletoDialog: false,
			pedidoBoleto: null,
			opcaoRegFinancDialog: false,
			boletoVencimento: null,
			clienteCpfCnpj: null,
			numeroBoleto: null,
			valorBoleto: null,
			loadingBoleto: false,
			baixarPdfBoleto: true,
			compartilharBoleto: false
		};
	},
	created() {
		this.UFs = Object.keys(_enum.UF);
		for (const formaPagamento of this.tiposPagamento) {
			this.pagamentosSelecionados[formaPagamento] = {
				selecionado: false,
				valor: 0,
				dataVencimento: null,
				numeroCheque: null,
				formaPagamento: null,
				nomeBanco: null
			};
		}
		if (!localStorage.token) {
			this.$router.push('/login');
		}

		this.carregarEstabelecimentos();
	},
	mounted() {
		window.onmouseover = (e) => {
			this.$x = e.clientX;
			this.$y = e.clientY;
		};
		if (!this.$vuetify.breakpoint.mdAndUp) {
			this.indicacaoCompra = _enum.CANAL_VENDA.CELULAR;
		}
		this.carregarUserInfo();
	},
	methods: {
		salvarCliente() {
			if (this.$refs.form.validate()) {
				if (this.cliente._id != null) {
					this.atualizarCliente();
				}
				else {
					this.cadastrarCliente();
				}
			}
		},
		cupomDialog() {
			if (this.validate(false)) {
				this.emitNfceOnly = true;
				this.nfceValues.docCliente = this.cliente.tipoPessoa == _enum.TIPO_PESSOA.PESSOA_JURIDICA ? this.cliente.cnpj : this.cliente.cpf;
				this.nfceValues.to = this.cliente.email;
				this.nfcePopup = true;
			}
		},
		getTipoPagamento(tipoPagamento) {
			switch (tipoPagamento) {
				case _enum.TIPO_PAGAMENTO.BOLETO: return 'boleto';
				case _enum.TIPO_PAGAMENTO.CARTAO_DEBITO: return 'cartao_debito';
				case _enum.TIPO_PAGAMENTO.CARTAO_CREDITO: return 'cartao_credito';
				case _enum.TIPO_PAGAMENTO.VENDA_ANTECIPADA: return 'venda_antecipada';
				case _enum.TIPO_PAGAMENTO.DINHEIRO: return 'dinheiro';
				case _enum.TIPO_PAGAMENTO.VALE_GAS: return 'valeGas';
				case _enum.TIPO_PAGAMENTO.CHEQUE: return 'cheque';
				case _enum.TIPO_PAGAMENTO.FIADO: return 'fiado';
				case _enum.TIPO_PAGAMENTO.TRANSFERENCIA: return 'transferencia';
				case _enum.TIPO_PAGAMENTO.REQUISICAO: return 'requisicao';
				case _enum.TIPO_PAGAMENTO.PIX: return 'pix';
				case _enum.TIPO_PAGAMENTO.VALE_AGUA: return 'vale_agua';
				case _enum.TIPO_PAGAMENTO.PICPAY: return 'picpay';
			}
		},
		getBody(agendado) {
			const _pagamentos = Object.keys(this.pagamentosSelecionados).filter(key => this.pagamentosSelecionados[key].selecionado).map(key => {
				return {
					tipoPagamento: key,
					...this.pagamentosSelecionados[key],
					selecionado: undefined
				};
			});
			const pagamentos = _pagamentos.map(pagamento => {
				const pag = { ...pagamento };
				pag.tipoPagamento = (() => {
					switch (pagamento.tipoPagamento) {
						case _enum.TIPO_PAGAMENTO.BOLETO: return 'boleto';
						case _enum.TIPO_PAGAMENTO.CARTAO_DEBITO: return 'cartao_debito';
						case _enum.TIPO_PAGAMENTO.CARTAO_CREDITO: return 'cartao_credito';
						case _enum.TIPO_PAGAMENTO.DINHEIRO: return 'dinheiro';
						case _enum.TIPO_PAGAMENTO.VALE_GAS: return 'valeGas';
						case _enum.TIPO_PAGAMENTO.CHEQUE: return 'cheque';
						case _enum.TIPO_PAGAMENTO.FIADO: return 'fiado';
						case _enum.TIPO_PAGAMENTO.TRANSFERENCIA: return 'transferencia';
						case _enum.TIPO_PAGAMENTO.REQUISICAO: return 'requisicao';
						case _enum.TIPO_PAGAMENTO.PIX: return 'pix';
						case _enum.TIPO_PAGAMENTO.VALE_AGUA: return 'vale_agua';
						case _enum.TIPO_PAGAMENTO.PICPAY: return 'picpay';
					}
				})();
				pag.formaPagamento = (() => {
					switch (pagamento.formaPagamento) {
						case _enum.FORMA_PAGAMENTO.A_VISTA: return 'a_vista';
						case _enum.FORMA_PAGAMENTO.A_PRAZO: return 'a_prazo';
					}
				})();
				for (const key in pag) {
					if (pag[key] == null) {
						delete pag[key];
					}
				}
				return pag;
			});

			return {
				pagamentos,
				parcelamento: this.parcelamento,
				valorTotal: this.obterTotalDaCompra(),
				data: this.dataEntrega,
				dataEntrega: this.dataEntrega,
				estabelecimento: this.obterEstabelecimentoFormatado(),
				troco: Number(this.troco),
				cliente: this.obterClienteFormatado(),
				produtos: this.pedidoItems.map(pedidoItem => ({
					isDefault: false,
					idProduto: pedidoItem.produto._id,
					quantidade: pedidoItem.quantidade,
					valorNegociado: pedidoItem.utilizarValorNegociado ? pedidoItem.vn : undefined,
					valorCusto: pedidoItem.tipoEstoque == _enum.TIPO_ESTOQUE.ESTOQUE_VAZIO ? pedidoItem.produto.valorCustoVazio : pedidoItem.produto.valorCustoCheio,
					valorCompleto: pedidoItem.tipoEstoque == _enum.TIPO_ESTOQUE.ESTOQUE_VAZIO ? pedidoItem.produto.valorVazio : pedidoItem.produto.valorCheio,
					estoqueSelecionado: {
						tipoEstoque: (() => {
							if (pedidoItem.produto.isUnico) return '3';
							switch (pedidoItem.tipoEstoque) {
								case _enum.TIPO_ESTOQUE.ESTOQUE_CHEIO: return '2';
								case _enum.TIPO_ESTOQUE.ESTOQUE_VAZIO: return '1';
							}
						})()
					}
				})),
				pagamentosNoPedido: pagamentos,
				status: agendado ? '1' : '5',
				agendado,
				entregadorIdEntregador: this.entregadorId,
				dataRepasse: this.entregadorId ? this.dataEntrega : undefined,
				timestampSistemaUsuario: Date.now(),
				isPedidoPortaria: false,
				observacao: this.observacao,
				indicacaoCompra: (() => {
					switch (this.indicacaoCompra) {
						case _enum.CANAL_VENDA.EMAIL: return 1;
						case _enum.CANAL_VENDA.IMA: return 2;
						case _enum.CANAL_VENDA.OUTDOOR: return 3;
						case _enum.CANAL_VENDA.INTERNET: return 4;
						case _enum.CANAL_VENDA.REDE_SOCIAL: return 5;
						case _enum.CANAL_VENDA.WHATSAPP: return 6;
						case _enum.CANAL_VENDA.APLICATIVO: return 7;
						case _enum.CANAL_VENDA.PORTARIA: return 8;
						case _enum.CANAL_VENDA.TELEATENDIMENTO: return 9;
						case _enum.CANAL_VENDA.PLANTAO: return 10;
						case _enum.CANAL_VENDA.VENDA_A_PORTA: return 11;
						case _enum.CANAL_VENDA.SINALIZACAO: return 12;
						case _enum.CANAL_VENDA.IMPRESSO: return 13;
						case _enum.CANAL_VENDA.CLIENTE_FIDELIZADO: return 14;
						case _enum.CANAL_VENDA.AUTOMATICA: return 15;
						case _enum.CANAL_VENDA.AGREGADO: return 16;
						case _enum.CANAL_VENDA.COMERCIO_INDUSTRIA: return 17;
						case _enum.CANAL_VENDA.CELULAR: return 18;
						case _enum.CANAL_VENDA.TELEFONE: return 19;
						case _enum.CANAL_VENDA.CH_APP: return 20;
						case _enum.CANAL_VENDA.PG_APP: return 21;
						case _enum.CANAL_VENDA.UP_APP: return 22;
						case _enum.CANAL_VENDA.QRCODEAPP: return 23;
						case _enum.CANAL_VENDA.RADIO: return 24;
						default: return null;
					}
				})()
			};
		},
		async downloadFromUrl(url, fileName = `cupom-fiscal-${dayjs().format('YYYY-MM-DD-HH-mm-SSS')}.pdf`) {
			const { data } = await this.axios.get('/apis/generico', {
				responseType: 'blob',
				params: {
					url
				}
			});
			const urlObject = URL.createObjectURL(data);
			const a = document.createElement('a');
			a.href = urlObject;
			a.download = fileName;
			a.click();
			URL.revokeObjectURL(urlObject);
		},
		showWarningValorNegociado(produtoSel) {
			if (produtoSel != null) {
				const valorProduto = produtoSel.tipoEstoque == _enum.TIPO_ESTOQUE.ESTOQUE_CHEIO ? produtoSel.produto.valorCheio : produtoSel.produto.valorVazio;
				if (this.userInfo != null && (this.userInfo.limiteDesconto != null || this.userInfo.limiteDescontoReais != null)) {
					const desconto = valorProduto - Number(produtoSel.vn);
					const descontoPorcentagem = (desconto / valorProduto) * 100;
					if ((this.userInfo.limiteDescontoReais || 0) > 0 && desconto > this.userInfo.limiteDescontoReais) {
						const porcentagemDescontoLimite = Math.round((this.userInfo.limiteDescontoReais / valorProduto) * 100);
						this.percentualValue = porcentagemDescontoLimite;
						return true;
					}
					if ((this.userInfo.limiteDesconto || 0) > 0 && descontoPorcentagem > this.userInfo.limiteDesconto) {
						this.percentualValue = this.userInfo.limiteDesconto;
						return true;
					}
					return false;
				}
				let percentualValue = this.getPercentageLimit(produtoSel);
				if (percentualValue != null && produtoSel.vn < valorProduto) {
					let value = valorProduto - Number(produtoSel.vn);
					if ((value / valorProduto * 100) > percentualValue) {
						this.percentualValue = percentualValue;
						return true;
					}
				}
			}
			this.percentualValue = null;
			return false;
		},
		getPercentageLimit(produtoSel) {
			if (produtoSel != null) {
				let percentualValue = produtoSel._configLimiteValorNegociado;
				if (percentualValue == null) {
					for (const emp of (produtoSel.empresas || [])) {
						if (emp._configLimiteValorNegociado != null) {
							percentualValue = emp._configLimiteValorNegociado;
							break;
						}
					}
					if (percentualValue == null) {
						for (const loja of (produtoSel.lojas || [])) {
							if (loja._configLimiteValorNegociado != null) {
								percentualValue = loja._configLimiteValorNegociado;
								break;
							}
						}
					}
				}
				return percentualValue;
			}
			return null;
		},
		async emitirCupom() {
			this.nfceLoading = true;
			try {
				const { data } = await this.axios.post(`/pedidos/emitir_nota_teleatendimento/${localStorage.userId}`, {
					docCliente: this.nfceValues.docCliente,
					emailToSend: this.nfceValues.to,
					idSmtpConfig: this.nfceValues.idSmtpConfig,
					sendToEmail: this.nfceValues.sendToEmail,
					infAdicionais: this.nfceValues.infAdicionais,
					pedido: this.getBody()
				});
				if (data.url) {
					this.nfcePopup = false;
					if (this.nfceValues.baixarPdf) {
						console.log(data);
						this.downloadFromUrl(data.url);
						const a = document.createElement('a');
						a.href = data.url;
						a.download = `${data.chave}.pdf`;
						a.click();
					}
					if (this.nfceValues.compartilhar) {
						try {
							await navigator.share(
								{
									title: 'Compartilhar cupom fiscal (NFCe)',
									text: `Segue o cupom fiscal (NFCe) emitida em ${dayjs().format('DD/MM/YYYY HH:mm')}`,
									url: data.url
								},
								SHARE_OPTIONS
							);
						}
						catch (error) {
							bus.$emit('error', error.message);
						}
					}
					this.$router.push('/refresh');
				}
			}
			catch (err) { }
			this.nfceLoading = false;
			bus.$emit('success', 'Pedido realizado com sucesso');
		},
		async emitirNota() {
			this.nfceLoading = true;
			try {
				const { data } = await this.axios.post(`/pedidos/emitir_nota/${this.pedidoEmitido.idPedido}/${localStorage.userId}`, {
					docCliente: this.nfceValues.docCliente,
					emailToSend: this.nfceValues.to,
					idSmtpConfig: this.nfceValues.idSmtpConfig,
					sendToEmail: this.nfceValues.sendToEmail
				});
				if (data.url) {
					this.nfcePopup = false;
					if (this.nfceValues.baixarPdf) {
						this.downloadFromUrl(data.url);
						const a = document.createElement('a');
						a.href = data.url;
						a.download = `${data.chave}.pdf`;
						a.click();
					}
					if (this.nfceValues.compartilhar) {
						try {
							await navigator.share(
								{
									title: 'Compartilhar cupom fiscal (NFCe)',
									text: `Segue o cupom fiscal (NFCe) reference ao pedido entregue em ${dayjs(this.pedidoEmitido.data).format('DD/MM/YYYY HH:mm')}`,
									url: data.url
								},
								{
									copy: true,
									email: true,
									print: true,
									sms: true,
									messenger: true,
									facebook: false,
									whatsapp: true,
									twitter: false,
									linkedin: false,
									telegram: true,
									skype: false,
									pinterest: false,
									language: 'pt'
								}
							);
						}
						catch (error) {
							bus.$emit('error', error.message);
						}
					}
					this.$router.push('/refresh');
				}
			}
			catch (err) { }
			this.nfceLoading = false;
		},
		async carregarSmtpConfigs() {
			try {
				this.smtpConfigLoading = true;
				try {
					const { data } = await this.axios.get(`/smtp/${localStorage.userId}`, {
						params: {
							[this.estabelecimento.idEmpresa ? 'multiEmpresa' : 'multiLoja']: this.estabelecimento._id
						}
					});
					this.smtpConfigs = data;
					if (data.length == 1) {
						this.nfceValues.idSmtpConfig = data[0]._id;
					}
				}
				catch (err) { }
				this.smtpConfigLoading = false;
			}
			catch (err) { }
		},
		async carregarTaxas() {
			this.loadingTaxas = true;
			try {
				const { data } = await this.axios.get(`/taxaTransacaoCartoes/list/${localStorage.userId}`, {
					params: {
						[this.estabelecimento.idEmpresa ? 'empresa' : 'loja']: this.estabelecimento._id
					}
				});
				this.taxas = data;
			}
			catch (err) { }
			this.loadingTaxas = false;
		},
		async carregarUserInfo() {
			this.loadingTaxas = true;
			try {
				const { data } = await this.axios.get(`/usuarios/userinfoFunc/${localStorage.userId}`, { });
				this.userInfo = data;
				// Valida se quais formas de pagamento o usuário tem disponível. 
				// Obs.: Caso o array "pagamentos_acessiveis" esteja vazio isso 
				// quer dizer que ele tem acesso a todas as formas de pagamento 
				if(data.usuarioFuncao && data.usuarioFuncao.pagamentos_acessiveis && data.usuarioFuncao.pagamentos_acessiveis.length > 0) {
					this.tiposPagamento = this.tiposPagamento.filter((tipo) => data.usuarioFuncao.pagamentos_acessiveis.includes(this.getTipoPagamento(tipo)) );
				}
			}
			catch (err) { }
			this.loadingTaxas = false;
		},

		async atualizarCliente() {
			this.loading = true;
			try {
				await this.axios.put(`/clientes/${localStorage.userId}`, this.obterClienteFormatado());
				bus.$emit('success', 'Cliente atualizado com sucesso');
				this.$router.push('/refresh');
			}
			catch (err) { }
			this.loading = false;
		},
		async cadastrarCliente(substituir = false) {
			this.loading = true;
			try {
				await this.axios.post(`/clientes/${localStorage.userId}`, this.obterClienteFormatado(substituir)).then(
					(res) => {

						if(res['data'].object && res['data'].object['clienteExistente']) {
							this.clienteDialog = true;
							this.infoCliente = {
								telefone: this.cliente.telefonePrincipal,
								...res.object['_doc']
							};
						} else {
							bus.$emit('success', 'Cliente cadastrado com sucesso');
							this.$router.push('/refresh');
						}
					}
				);
			}
			catch (err) { }
			this.loading = false;
		},
		fecharAgendamento() {
			this.agendamentoDialog = false;
			this.dataEntrega = this.dataEntrega || null;
		},
		obterEstabelecimentoFormatado() {
			const tipoEst = this.estabelecimento.idEmpresa ? 'empresa' : 'loja';
			return {
				tipoEstabelecimento: tipoEst,
				empresa: tipoEst == 'empresa' ? {
					idEmpresa: this.estabelecimento._id,
					_id: this.estabelecimento._id
				} : null,
				loja: tipoEst == 'loja' ? {
					idLoja: this.estabelecimento._id,
					_id: this.estabelecimento._id
				} : null,
				nome: this.estabelecimento.nomeFantasia,
				estLocalId: `${tipoEst == 'empresa' ? 'emp' : 'loj'}${this.estabelecimento._id}`,
				id: this.estabelecimento._id
			};
		},
		obterClienteFormatado(substituir = false) {
			return {
				...('_id' in this.cliente ? { _id: this.cliente._id } : {}),
				...('limiteDivida' in this.cliente ? { limiteDivida: this.cliente.limiteDivida } : {}),
				...('codigo' in this.cliente ? { codigoCliente: this.cliente.codigo } : {}),
				...('nome' in this.cliente ? { nome: this.cliente.nome } : {}),
				...('telefonePrincipal' in this.cliente ? { telefone: this.cliente.telefonePrincipal } : {}),
				...('telefones' in this.cliente ? { outrosTelefones: this.cliente.telefones } : {}),
				...('email' in this.cliente ? { email: this.cliente.email } : {}),
				...('cpf' in this.cliente ? { cpf: this.cliente.cpf } : {}),
				...('tipoPessoa' in this.cliente ? { tipoCliente: this.cliente.tipoPessoa == _enum.TIPO_PESSOA.PESSOA_JURIDICA ? '2' : '1' } : {}),
				...('cnpj' in this.cliente ? { cnpj: this.cliente.cnpj } : {}),
				...('nome' in this.cliente ? { nomeFantasia: this.cliente.nome } : {}),
				...('inscricaoEstadual' in this.cliente ? { inscricaoEstadual: this.cliente.inscricaoEstadual } : {}),
				...('inscricaoMunicipal' in this.cliente ? { inscricaoMunicipal: this.cliente.inscricaoMunicipal } : {}),
				...('razaoSocial' in this.cliente ? { razaoSocial: this.cliente.razaoSocial } : {}),
				...('ativo' in this.cliente ? { ativo: this.cliente.ativo } : { ativo: true }),
				...('aniversario' in this.cliente ? { dtAniversario: this.cliente.aniversario } : {}),
				...('perfil' in this.cliente ? {
					perfil: (() => {
						switch (this.cliente.perfil) {
							case _enum.PERFIL_CLIENTE.CONSUMIDOR_FINAL: return '1';
							case _enum.PERFIL_CLIENTE.COMERCIO: return '2';
							case _enum.PERFIL_CLIENTE.PORTARIA: return '3';
							case _enum.PERFIL_CLIENTE.ATACADO: return '4';
							case _enum.PERFIL_CLIENTE.AVIARIO: return '5';
							case _enum.PERFIL_CLIENTE.RESIDENCIAL: return '6';
							case _enum.PERFIL_CLIENTE.SUPERMERCADO: return '7';
							case _enum.PERFIL_CLIENTE.INDUSTRIAL: return '8';
							case _enum.PERFIL_CLIENTE.ESCOLA: return '9';
							case _enum.PERFIL_CLIENTE.PREFEITURA: return '10';
							case _enum.PERFIL_CLIENTE.CONDOMINIO: return '11';
							case _enum.PERFIL_CLIENTE.PADARIA: return '12';
							case _enum.PERFIL_CLIENTE.RESTAURANTE: return '13';
							case _enum.PERFIL_CLIENTE.BAR: return '14';
							case _enum.PERFIL_CLIENTE.HOSPITAL: return '15';
							case _enum.PERFIL_CLIENTE.IGREJA: return '16';
							case _enum.PERFIL_CLIENTE.CONCORRENTE: return '17';
							case _enum.PERFIL_CLIENTE.REVENDEDOR: return '18';
							case _enum.PERFIL_CLIENTE.FILIAL: return '19';
							case _enum.PERFIL_CLIENTE.FUNCIONARIO: return '20';
						}
					})()
				} : {}),
				...('prazoVencimento' in this.cliente ? { prazoMaximo: this.cliente.prazoVencimento } : {}),
				...('tipoCFOP' in this.cliente ? { tipoCFOP: this.cliente.tipoCFOP == _enum.TIPO_CFOP.CONSUMIDOR_FINAL ? 'consumidor_final' : 'ponto_de_venda' } : {}),
				...('produtoId' in this.cliente ? { produtoIdProduto: this.cliente.produtoId } : {}),
				...('_id' in this.cliente ? { idCliente: this.cliente._id } : {}),
				...('endereco' in this.cliente ? {
					enderecoDefault: {
						...('_id' in this.cliente.endereco ? { _id: this.cliente.endereco._id } : {}),
						...('logradouro' in this.cliente.endereco ? { endereco: this.cliente.endereco.logradouro } : {}),
						...('numero' in this.cliente.endereco ? { numero: this.cliente.endereco.numero } : {}),
						...('complemento' in this.cliente.endereco ? { complemento: this.cliente.endereco.complemento } : {}),
						...('cep' in this.cliente.endereco ? { cep: this.cliente.endereco.cep } : {}),
						...('bairro' in this.cliente.endereco ? { bairro: this.cliente.endereco.bairro } : {}),
						...('observacao' in this.cliente.endereco ? { observacao: this.cliente.endereco.observacao } : {}),
						...('localidade' in this.cliente.endereco ? { cidade: this.cliente.endereco.localidade } : {}),
						...('uf' in this.cliente.endereco ? { estadoAcronimo: this.cliente.endereco.uf } : {}),
						...('ibge' in this.cliente.endereco ? { estadoCod: this.cliente.endereco.ibge ? this.cliente.endereco.ibge.substr(0, 2) : undefined } : {}),
						...('ibge' in this.cliente.endereco ? { codigoMunicipio: this.cliente.endereco.ibge } : {}),
						...('_id' in this.cliente.endereco ? { idEndereco: this.cliente.endereco._id } : {}),
						...('uf' in this.cliente.endereco ? { uf: this.cliente.endereco.uf } : {})
					}
				} : {}),
				...('prazoVencimento' in this.cliente ? { prazoMaximo_formated: dayjs(new Date()).add(this.cliente.prazoVencimento, 'days').toDate() } : {}),
				estabelecimento: this.obterEstabelecimentoFormatado(),
				componentId: 'teleatendimento:lancar-pedido',
				substituir: substituir,
			};
		},
		fecharNFCePopup() {
			this.nfcePopup = false;
			bus.$emit('success', 'Pedido realizado com sucesso');
			this.$router.push('/refresh');
		},
		finalizarNFCe() {
			if (this.podeEmitirNFCE) {
				this.nfceValues.docCliente = this.cliente.tipoPessoa == _enum.TIPO_PESSOA.PESSOA_JURIDICA ? this.cliente.cnpj : this.cliente.cpf;
				this.nfceValues.to = this.cliente.email;
				this.emitNfceOnly = false;
				this.nfcePopup = true;
			}
			else {
				bus.$emit('success', 'Pedido realizado com sucesso');
				this.$router.push('/refresh');
			}
		},
		async enviarPedido(agendado = false) {
			if (this.validate()) {
				this.loading = true;
				try {
					this.agendamentoDialog = false;
					const { data } = await this.axios.post(`/pedidos/lancamento2/${localStorage.userId}`, this.getBody(agendado));
					this.pedidoEmitido = data.object;
					if (this.pagamentosSelecionados.Boleto.selecionado) {
						this.opcaoBoletoDialog = true;
						this.pedidoBoleto = data.object._id;
					}
					else {
						this.finalizarNFCe();
					}
				}
				catch (err) { }
				this.loading = false;
			}
		},
		obterValorRestante() {
			return Number(this.obterTotalDaCompra()) - Object.values(this.pagamentosSelecionados).reduce((acc, pag) => acc + (pag.selecionado ? Number(pag.valor) : 0), 0);
		},
		validate(validateForm = true) {
			if (validateForm && !this.$refs.form.validate()) {
				bus.$emit('error', 'Existem campos obrigatórios não preenchidos');
				return false;
			}
			if (Object.values(this.pagamentosSelecionados).every(pag => !pag.selecionado)) {
				bus.$emit('error', 'Selecione uma forma de pagamento');
				return false;
			}
			if (!this.pedidoItems.length) {
				bus.$emit('error', 'Selecione pelo menos um produto');
				return false;
			}
			if (Object.values(this.pagamentosSelecionados).some(pag => pag.selecionado && (!pag.valor || pag.valor == 0))) {
				bus.$emit('error', 'Existem formas de pagamento sem valor');
				return false;
			}
			return true;
		},
		verificarBloqueio(pagamentos = []) {

			if (this.msgPagBloqueadoTimeout) clearTimeout(this.msgPagBloqueadoTimeout);

			/** Verifica se o cliente tem bloqueio nessa forma de pagamento */
			if (this.cliente && this.cliente.tipoPagamentoBloqueado && pagamentos.find( pag => this.cliente.tipoPagamentoBloqueado.includes(this.getTipoPagamento(pag)) ) != null) {
				let pagBloq = pagamentos.find( pag => this.cliente.tipoPagamentoBloqueado.includes(this.getTipoPagamento(pag)));
				this.showPagamentoBloqueadoText = true;
				// Por algum motivo se esse timeout não for executado, o checkbox 
				// não é desmarcado mesmo com o v-model = false. By Pedro Santos
				this.msgPagBloqueadoTimeout = setTimeout(() => {
					this.togglePagamento(pagBloq, false);
				}, 1000);
				// Timeout da mensagem de pagamento bloqueado. By Pedro Santos
				this.msgPagBloqueadoTimeout = setTimeout(() => {
					this.showPagamentoBloqueadoText = false;
				}, 10000);

			} else {
				this.showPagamentoBloqueadoText = false;
			}
		},

		togglePagamento(formaPagamento, selecionado) {
			this.mostrarCampoValor = Object.values(this.pagamentosSelecionados).filter(p => p.selecionado).length > 1;
			if (selecionado) {
				if (this.mostrarCampoValor || ![
					_enum.TIPO_PAGAMENTO.VALE_GAS,
					_enum.TIPO_PAGAMENTO.TRANSFERENCIA,
					_enum.TIPO_PAGAMENTO.REQUISICAO,
					_enum.TIPO_PAGAMENTO.VENDA_ANTECIPADA,
					_enum.TIPO_PAGAMENTO.PIX,
					_enum.TIPO_PAGAMENTO.VALE_AGUA,
					_enum.TIPO_PAGAMENTO.PICPAY
				].includes(formaPagamento)) {
					this.pagamentoPopup = formaPagamento;
				}
				this.valorTaxa = 0;
				this.popupValues = this.pagamentosSelecionados[formaPagamento];
				if (!this.popupValues.valor || this.popupValues.valor == 0) {
					this.popupValues.valor = this.obterValorRestante();
				}
			}
			else {
				this.pagamentoPopup = false;
				this.pagamentosSelecionados[formaPagamento] = {
					selecionado: false,
					valor: 0,
					dataVencimento: new Date(),
					numeroCheque: null,
					formaPagamento: null,
					nomeBanco: null
				};
			}
			this.pagamentosSelect = [];
			for (const pag in this.pagamentosSelecionados) {
				if (this.pagamentosSelecionados[pag].selecionado) {
					this.pagamentosSelect.push(pag);
				}
			}
			if(this.cliente !== undefined && this.cliente !== null && selecionado) {
				this.verificarBloqueio(this.pagamentosSelect);
			}
			this.$nextTick(() => {
				this.x = this.$x;
				this.y = this.$y;
			});
		},
		logout() {
			localStorage.clear();
			this.$router.push('/login');
		},
		limparCliente() {
			this.cliente = {
				_id: null,
				endereco: {},
				telefones: [],
				tipoPessoa: _enum.TIPO_PESSOA.PESSOA_FISICA,
				ativo: true
			};
		},
		obterValorUnitario(item) {
			return item.tipoEstoque ? (item.utilizarValorNegociado ? item.vn : (item.tipoEstoque == _enum.TIPO_ESTOQUE.ESTOQUE_CHEIO ? item.produto.valorCheio : item.produto.valorVazio)) : 0;
		},
		obterValorTotal(item) {
			const valorUnitario = this.obterValorUnitario(item);
			return valorUnitario && item.quantidade ? valorUnitario * item.quantidade : 0;
		},
		obterTotalDaCompra() {
			return this.pedidoItems.reduce((accum, item) => accum + Number(this.obterValorTotal(item)), 0);
		},
		openAgendamento() {
			if (this.validate()) {
				this.agendamentoDialog = true;
			}
		},
		async carregarEstabelecimentos() {
			this.loadingEstabelecimentos = true;
			try {
				const { data: empresas } = await this.axios.get(`/empresas/list/${localStorage.userId}`);
				const { data: lojas } = await this.axios.get(`/lojas/list/${localStorage.userId}`);
				this.estabelecimentos = [...empresas, ...lojas];
				const estOnStorage = localStorage.estabelecimento ? this.estabelecimentos.find(est => est._id == localStorage.estabelecimento) : null;
				if (estOnStorage) {
					this.estabelecimento = estOnStorage;
				}
				else if (this.estabelecimentos.length == 1) {
					this.estabelecimento = this.estabelecimentos[0];
				}
			}
			catch (err) { }
			this.loadingEstabelecimentos = false;
		},
		async carregarClientes() {
			this.loadingClientes = true;
			try {
				let searchString = null;
				let field = null;
				if (this.clienteDocumento) {
					searchString = this.clienteDocumento;
					field = 'documento';
				}
				else if (this.clienteTelefone) {
					searchString = this.clienteTelefone;
					field = 'telefone';
				}
				else if (this.clienteNome) {
					searchString = this.clienteNome;
					field = 'nome';
				}
				else if (this.clienteEndereco) {
					searchString = this.clienteEndereco;
					field = 'endereco';
				}
				if (searchString && field) {
					const { data } = await this.axios.get(`/clientes/list/${localStorage.userId}`, {
						params: {
							field,
							searchString,
							page: 1,
							per_page: 100,
							[this.estabelecimento.idEmpresa ? 'multiEmpresa' : 'multiLoja']: this.estabelecimento._id
						}
					});
					this.clientes = data.items;
				}
			}
			catch (err) { }
			this.loadingClientes = false;
		},
		async carregarProdutos() {
			this.produtos = [];
			this.loadingProdutos = true;
			try {
				const { data } = await this.axios.get(`/produtos/list/${localStorage.userId}`, {
					params: {
						[this.estabelecimento.idEmpresa ? 'multiEmpresa' : 'multiLoja']: this.estabelecimento._id
					}
				});
				this.produtos = data;
				const produtoPadrao = this.produtos.find(prod => prod.isDefault);
				if (produtoPadrao && this.pedidoItems.length == 0) {
					this.addProduto(produtoPadrao);
				}
			}
			catch (err) { }
			this.loadingProdutos = false;
		},
		async carregarEntregadores() {
			this.loadingEntregadores = true;
			try {
				const { data } = await this.axios.get(`/entregadores/list/${localStorage.userId}`, {
					params: {
						[this.estabelecimento.idEmpresa ? 'empresa' : 'loja']: this.estabelecimento._id
					}
				});
				this.entregadores = data;
			}
			catch (err) { }
			this.loadingEntregadores = false;
		},
		resetBuscaCliente(field) {
			if (field != 'documento') this.clienteDocumento = null;
			if (field != 'telefone') this.clienteTelefone = null;
			if (field != 'nome') this.clienteNome = null;
			if (field != 'endereco') this.clienteEndereco = null;
		},
		openBuscarCliente() {
			this.resetBuscaCliente();
			this.clientes = [];
			this.buscarCliente = true;
		},
		selecionarCliente(cliente) {
			this.valoresNegociados = [];
			const endereco = getEnderecoDefault(cliente.enderecos);
			this.cliente = {
				_id: cliente._id,
				codigo: cliente.codigoCliente || null,
				limiteDivida: cliente.limiteDivida || null,
				nome: cliente.nome || null,
				telefones: (cliente.outrosTelefones || []).map(x => formatPhone(x)),
				telefonePrincipal: formatPhone(cliente.telefone) || null,
				email: cliente.email || null,
				tipoPessoa: cliente.tipoCliente == 1 ? _enum.TIPO_PESSOA.PESSOA_FISICA : _enum.TIPO_PESSOA.PESSOA_JURIDICA,
				cpf: clear(cliente.cpf) || null,
				cnpj: clear(cliente.cnpj) || null,
				razaoSocial: cliente.razaoSocial || null,
				inscricaoEstadual: cliente.inscricaoEstadual || null,
				inscricaoMunicipal: cliente.inscricaoMunicipal || null,
				aniversario: cliente.dtAniversario || null,
				prazoVencimento: cliente.prazoMaximo || null,
				tipoPagamentoBloqueado: cliente.tipoPagamentoBloqueado || null,
				_credito: 0,
				tipoCFOP: (() => {
					switch (cliente.tipoCFOP) {
						case 'ponto_de_venda': return _enum.TIPO_CFOP.PONTO_VENDA;
						default: return _enum.TIPO_CFOP.CONSUMIDOR_FINAL;
					}
				})(),
				perfil: (() => {
					switch (cliente.perfil) {
						case '1': return _enum.PERFIL_CLIENTE.CONSUMIDOR_FINAL;
						case '2': return _enum.PERFIL_CLIENTE.COMERCIO;
						case '3': return _enum.PERFIL_CLIENTE.PORTARIA;
						case '4': return _enum.PERFIL_CLIENTE.ATACADO;
						case '5': return _enum.PERFIL_CLIENTE.AVIARIO;
						case '6': return _enum.PERFIL_CLIENTE.RESIDENCIAL;
						case '7': return _enum.PERFIL_CLIENTE.SUPERMERCADO;
						case '8': return _enum.PERFIL_CLIENTE.INDUSTRIAL;
						case '9': return _enum.PERFIL_CLIENTE.ESCOLA;
						case '10': return _enum.PERFIL_CLIENTE.PREFEITURA;
						case '11': return _enum.PERFIL_CLIENTE.CONDOMINIO;
						case '12': return _enum.PERFIL_CLIENTE.PADARIA;
						case '13': return _enum.PERFIL_CLIENTE.RESTAURANTE;
						case '14': return _enum.PERFIL_CLIENTE.BAR;
						case '15': return _enum.PERFIL_CLIENTE.HOSPITAL;
						case '16': return _enum.PERFIL_CLIENTE.IGREJA;
						case '17': return _enum.PERFIL_CLIENTE.CONCORRENTE;
						case '18': return _enum.PERFIL_CLIENTE.REVENDEDOR;
						case '19': return _enum.PERFIL_CLIENTE.FILIAL;
						case '20': return _enum.PERFIL_CLIENTE.FUNCIONARIO;
					}
				})(),
				endereco: {
					_id: endereco._id,
					cep: endereco.cep,
					uf: endereco.estadoAcronimo,
					localidade: endereco.cidade,
					bairro: endereco.bairro,
					logradouro: endereco.endereco,
					complemento: endereco.complemento,
					numero: endereco.numero,
					ibge: endereco.codigoMunicipio,
					observacao: endereco.observacao,
				},
				produtoId: cliente.produto || null
			};
			this.buscarCliente = false;
			this.valoresNegociados = cliente.valorNegociados.reverse();
			this.nfceValues.docCliente = this.cliente.tipoPessoa == _enum.TIPO_PESSOA.PESSOA_JURIDICA ? this.cliente.cnpj : this.cliente.cpf;
			this.nfceValues.to = this.cliente.email;
			this.verificarValorNegociado();
			this.getVendasAntecipadasCliente(cliente._id);
		},
		getVendasAntecipadasCliente(cliente) {

			this.axios.get(`/vendas/antecipada/buscar/cliente/${localStorage.userId}/${cliente}`, {}).then((res) => {

				this.cliente._credito = 0;
				(res.data || []).forEach(v => {
					if (v.tipo == 'credito') this.cliente._credito += Number(v.valor);
					else this.cliente._credito -= v.valor;
				});
				if (this.cliente._credito < 0) this.cliente._credito = 0;

			});
		},

		async addProduto(produtoSelecionado) {
			const itemExistente = this.pedidoItems.find(p => p.produtoId == produtoSelecionado._id);
			const itemExistenteCheio = this.pedidoItems.find(p => p.produtoId == produtoSelecionado._id && p.tipoEstoque == _enum.TIPO_ESTOQUE.ESTOQUE_CHEIO);
			const itemExistenteVazio = this.pedidoItems.find(p => p.produtoId == produtoSelecionado._id && p.tipoEstoque == _enum.TIPO_ESTOQUE.ESTOQUE_VAZIO);
			let tipoEstoque = null;
			if (itemExistente) {
				if (produtoSelecionado.isUnico) {
					this.$nextTick(() => {
						this.produto = null;
					});
					return null;
				}
				if (itemExistenteCheio && !itemExistenteVazio) {
					tipoEstoque = _enum.TIPO_ESTOQUE.ESTOQUE_VAZIO;
				}
				else if (itemExistenteVazio && !itemExistenteCheio) {
					tipoEstoque = _enum.TIPO_ESTOQUE.ESTOQUE_CHEIO;
				}
				else {
					this.$nextTick(() => {
						this.produto = null;
					});
					return null;
				}
			}
			else {
				tipoEstoque = _enum.TIPO_ESTOQUE.ESTOQUE_CHEIO;
			}
			const item = {
				nanoid: nanoid(36),
				produtoId: produtoSelecionado._id,
				produto: produtoSelecionado,
				tipoEstoque,
				quantidade: 1,
				utilizarValorNegociado: false,
				valorNegociadoId: null,
				vn: 0,
				_configLimiteValorNegociado: produtoSelecionado._configLimiteValorNegociado,
				empresas: produtoSelecionado.empresas || null,
				lojas: produtoSelecionado.lojas || null,
			};
			item.vn = this.obterValorUnitario(item);
			this.pedidoItems.push(item);
			this.verificarValorNegociado();
			this.$nextTick(() => {
				this.produto = null;
			});
		},
		verificarValorNegociado() {
			for (const item of this.pedidoItems) {
				const valorNegociado = this.valoresNegociados.find(vn => vn.produto == item.produtoId && (
					(vn.estoqueNegociado == 'estoqueNegociado:cheio' && item.tipoEstoque == _enum.TIPO_ESTOQUE.ESTOQUE_CHEIO) ||
					(vn.estoqueNegociado == 'estoqueNegociado:vazio' && item.tipoEstoque == _enum.TIPO_ESTOQUE.ESTOQUE_VAZIO) ||
					vn.estoqueNegociado == 'estoqueNegociado:todos'
				));
				if (valorNegociado) {
					item.utilizarValorNegociado = true;
					item.valorNegociadoId = valorNegociado.idValorNegociado;
					item.vn = valorNegociado.valor;
				}
			}
		},
		removeItem(item) {
			this.pedidoItems = this.pedidoItems.filter(x => !(x.produto._id == item.produto._id && x.tipoEstoque == item.tipoEstoque));
		},
		alterarTipoEstoque(item) {
			const itemFound = this.pedidoItems.find(it => it.nanoid != item.nanoid && it.produtoId == item.produtoId && it.tipoEstoque == item.tipoEstoque);
			if (itemFound) {
				itemFound.quantidade += item.quantidade;
				this.pedidoItems = this.pedidoItems.filter(it => it.nanoid != item.nanoid);
			}
		},
		editarVn(item) {
			this.item = item;
			this.itemCopy = JSON.parse(JSON.stringify(item));
			this.vndialog = true;
		},
		echarVndialog() {
			this.item = null;
			this.itemCopy = null;
			this.vndialog = false;
		},
		async salvarVn() {
			if (this.item.vn != this.itemCopy.vn) {
				try {
					const ultrapassouLimite = this.showWarningValorNegociado(this.itemCopy);

					if (ultrapassouLimite) {
						bus.$emit('error', 'Você negociou o produto com um desconto maior que o limite de '+this.percentualValue+'%!');
						return; 
					}
					this.vnLoading = true;
					const { data } = await this.axios.post(`/valoresNegociados/${localStorage.userId}`, {
						estoqueNegociado: (() => {
							switch (this.itemCopy.tipoEstoque) {
								case _enum.TIPO_ESTOQUE.ESTOQUE_CHEIO: return 'estoqueNegociado:cheio';
								case _enum.TIPO_ESTOQUE.ESTOQUE_VAZIO: return 'estoqueNegociado:vazio';
								default: return 'estoqueNegociado:todos';
							}
						})(),
						idCliente: this.cliente._id,
						idProduto: this.itemCopy.produtoId,
						valor: this.itemCopy.vn
					});
					this.item.vn = this.itemCopy.vn;
					this.item.utilizarValorNegociado = true;
					this.item.valorNegociadoId = data._id;
					this.vndialog = false;
					this.vnLoading = false;
				}
				catch (err) { }
			}
			else {
				this.vndialog = false;
			}
		},
		async setClienteCod() {
			try {
				const { data } = await this.axios.get(`/clientes/list/${localStorage.userId}`, {
					params: {
						entriesOnly: true,
						[this.estabelecimento.idEmpresa ? 'empresa' : 'loja']: this.estabelecimento._id
					}
				});
				this.cliente.codigo = data.entries + 1;
			}
			catch (err) { }
		},
		async emitirBoleto() {
			if (this.$refs.formBoleto.validate()) {
				this.loadingBoleto = true;
				try {
					const { data: cliente } = await this.axios.get(`/clientes/vg2/${this.cliente._id}`);
					const data = {
						idEmpresa: this.estabelecimento.idEmpresa,
						idLoja: this.estabelecimento.idLoja,
						vencimento: this.boletoVencimento,
						valor: this.pagamentosSelecionados.Boleto.valor,
						pedido: this.pedidoBoleto,
						idPedido: this.pedidoBoleto,
						cliente
					};
					const { data: boleto } = await this.axios.post(`/boleto/emitir_boleto/${localStorage.userId}`, data);
					if (this.baixarPdfBoleto) {
						this.downloadFromUrl(boleto.linkBoleto, `${boleto.nomeBoleto}.pdf`);
						const a = document.createElement('a');
						a.href = boleto.linkBoleto;
						a.download = `${boleto.nomeBoleto}.pdf`;
						a.click();
						this.opcaoBoletoDialog = false;
						this.pedidoBoleto = null;
						this.finalizarNFCe();
						
					}
					if (this.compartilharBoleto) {
						try {
							await navigator.share(
								{
									title: 'Compartilhar boleto',
									text: `Segue o boleto emitido em ${dayjs().format('DD/MM/YYYY HH:mm')}`,
									url: boleto.linkBoleto
								},
								SHARE_OPTIONS
							);
							this.opcaoBoletoDialog = false;
							this.pedidoBoleto = null;
							this.finalizarNFCe();
						}
						catch (error) {
							bus.$emit('error', error.message);
						}
					}
					
				}
				catch (err) {
					console.log(err);
				}
				this.loadingBoleto = false;
			}
		},
		naoEmitirBoleto() {
			this.opcaoBoletoDialog = false;
			this.finalizarNFCe();
		},
		async registrarFinanceiro() {
			
			this.loadingBoleto = true;
			this.opcaoRegFinancDialog = true;
			try {
				// const { data: cliente } = await this.axios.get(`/clientes/vg2/${this.cliente._id}`);
				const data = {
					idPedido:this.pedidoEmitido.idPedido,
					idEmpresa: this.estabelecimento.idEmpresa,
					idLoja: this.estabelecimento.idLoja,
					vencimento: this.boletoVencimento,
					valorBoleto: this.valorBoleto,
					numeroBoleto: this.numeroBoleto,
					regBoleto: this.opcaoRegFinancDialog,
				};
				await this.axios.post(`/pedidos/registrar_boleto/${localStorage.userId}`, data);

				this.finalizarNFCe();
			}
			catch (err) {
				console.log(err);
			}
			this.loadingBoleto = false;
		},
		async naoRegistrarFinanceiro() {
			this.opcaoRegFinancDialog = false;
			const data = {regBoleto: this.opcaoRegFinancDialog};
			await this.axios.post(`/pedidos/registrar_boleto/${localStorage.userId}`, data);
			this.finalizarNFCe();
		}
	},
	watch: {
		'estabelecimento._id'(v) {
			if (v) {
				localStorage.estabelecimento = v;
				this.pedidoItems = [];
				this.limparCliente();
				this.carregarProdutos();
				this.carregarEntregadores();
				this.setClienteCod();
				this.carregarSmtpConfigs();
				this.carregarTaxas();
			}
		},
		podeEmitirNFCE(v) {
			localStorage.podeEmitirNFCE = v;
		}
	}
};
</script>